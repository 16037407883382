import GATSBY_COMPILED_MDX from "/vercel/path0/content/blog-posts/secretos-belleza/index.mdx";
import React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {MDXProvider} from "@mdx-js/react";
import {SEO, Banner, Footer, Title, Author, FeaturedImage} from "components/Blog";
import {BannerRecap, EmbededImageLocal, EmbededImageLocalSide} from "components/Blog";
import {MdComponents} from "components/Blog";
import MainNavbar from "components/Navbar";
import MainFooter from "components/Footer";
import MainBanner from "components/Banner";
var shortcodes = {
  GatsbyImage: GatsbyImage,
  BannerRecap: BannerRecap,
  EmbededImageLocal: EmbededImageLocal,
  EmbededImageLocalSide: EmbededImageLocalSide
};
var components = MdComponents;
function Layout(_ref) {
  var children = _ref.children, _ref$data = _ref.data, mdx = _ref$data.mdx, allPagesYaml = _ref$data.allPagesYaml;
  return React.createElement(React.Fragment, null, React.createElement(MainBanner, {
    text: allPagesYaml.nodes[0].banner.text1 + " | " + allPagesYaml.nodes[0].banner.text2
  }), React.createElement(MainNavbar, null), React.createElement(Banner, {
    blog_title: mdx.frontmatter.title
  }), React.createElement("article", {
    itemScope: true,
    itemType: "https://schema.org/Article"
  }, React.createElement("meta", {
    itemProp: "datePublished",
    content: mdx.frontmatter.date
  }), React.createElement("meta", {
    itemProp: "image",
    content: mdx.frontmatter.seo.image
  }), React.createElement("meta", {
    itemProp: "publisher",
    content: "drplanascosmetica.com"
  }), React.createElement(FeaturedImage, {
    alt: mdx.frontmatter.featuredImage_alt,
    image: getImage(mdx.frontmatter.featuredImage)
  }), React.createElement("div", {
    id: "article-body",
    className: "max-w-5xl mx-auto -mt-20 bg-white px-20 pt-16"
  }, React.createElement(Title, {
    title: mdx.frontmatter.title
  }), React.createElement(MDXProvider, {
    components: components
  }, React.createElement(MDXProvider, {
    components: shortcodes
  }, children)), React.createElement(Author, {
    name: mdx.frontmatter.author
  }))), React.createElement("div", {
    id: "article-footer",
    className: "max-w-5xl mx-auto"
  }, React.createElement(Footer, null)), React.createElement(MainFooter, null));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Layout, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export var Head = function Head(_ref2) {
  var seo = _ref2.data.mdx.frontmatter.seo;
  return React.createElement(SEO, {
    title: seo.title,
    description: seo.description,
    image: seo.image
  });
};
var query = "2741477856";
