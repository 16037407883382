/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getImage, GatsbyImage, StaticImage} from "gatsby-plugin-image";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components), {EmbededImageLocal, BannerRecap, EmbededImageLocalSide} = _components;
  if (!BannerRecap) _missingMdxReference("BannerRecap", true);
  if (!EmbededImageLocal) _missingMdxReference("EmbededImageLocal", true);
  if (!EmbededImageLocalSide) _missingMdxReference("EmbededImageLocalSide", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "1. Dieta Equilibrada"), "\n", React.createElement(_components.p, null, "Lleva una dieta variada y equilibrada si quieres lucir una piel saludable. Las frutas y verduras deben ser una parte importante de su dieta diaria, ya que contienen muchas vitaminas y minerales que son buenos para la salud. Estos incluyen vitamina A (betacaroteno), C y E, magnesio, zinc, fósforo y ácido fólico. El ácido fólico puede reducir el nivel sanguíneo de homocisteína, que puede ser un factor de riesgo de enfermedad coronaria."), "\n", React.createElement(_components.p, null, "Trata de comer frutas y verduras variadas de temporada, evitando la ingesta de bebidas azucaradas, los fritos y dieta alta en carbohidratos."), "\n", React.createElement(EmbededImageLocal, {
    alt: "Comida sana de una dieta equilibrada",
    image: props.data.mdx.frontmatter.embeddedImagesLocal[0]
  }), "\n", React.createElement(_components.h1, null, "2. Protector Solar"), "\n", React.createElement(_components.p, null, "Protector solar, el mejor aliado de una piel saludable. Un hábito dentro de tu rutina de belleza debe ser la introducción del factor de protección solar. Asegúrese de que su protector solar tenga un factor de protección solar, de 30 o superior. Mientras más alto el número, mayor es el factor de protección solar.\nSu uso no solo tiene un poder antiaging, es decir, previene el envejecimiento prematuro de la piel sino que además reduce la probabilidad de tener cáncer de piel. Recuerda usarlo todos los días del año, ya que en la mayor parte de España, el clima es soleado durante los 365 días."), "\n", React.createElement(EmbededImageLocal, {
    alt: "Mujer protegiendo su piel con factor solar",
    image: props.data.mdx.frontmatter.embeddedImagesLocal[1]
  }), "\n", React.createElement(BannerRecap, {
    text: "Si tienes piel seca, usa protector solar de base cremosa. Si tu piel se normal, usa protector solar mate, ya que al final del día pueden aparecer brillos y si tienes piel grasa, recomiendo usar protector solar a base de gel."
  }), "\n", React.createElement(_components.h1, null, "3. Belleza Minimalista"), "\n", React.createElement(_components.p, null, "Siempre aconsejo a mis pacientes que cuando vayan a comprar algún producto, sea de cosmética o de alimentación, siempre lean la etiqueta. Mientras menos ingredientes tenga un producto, mejor."), "\n", React.createElement(_components.p, null, "Evita aditivos innecesarios y fragancias que pueden irritar tanto la piel como el tracto digestivo causando alteración de la microbiota intestinal, fotosensibilidad, dermatitis, eccemas, urticaria o microinflamaciónes."), "\n", React.createElement(EmbededImageLocal, {
    alt: "Producto de belleza innecesarios",
    image: props.data.mdx.frontmatter.embeddedImagesLocal[2]
  }), "\n", React.createElement(_components.h1, null, "4. Exfoliación"), "\n", React.createElement(_components.p, null, "¿Quieres lucir un cutis radiante? La exfoliación es uno de los pasos más importantes en una rutina de belleza. Exfoliar las células muertas de la piel tiene muchos beneficios, como reducir el exceso de sebo, liberar los poros obstruidos, prevenir el acné y los brillos de la cara, también, estimular la circulación sanguínea. Además, de esta forma, podemos ayudar a la piel a completar correctamente el proceso de regeneración celular."), "\n", React.createElement(_components.p, null, "Aconsejo utilizar exfoliantes suaves a base de ácido láctico o ácido glicólico. Si tienes una piel sensible, usa exfoliante fisiológico y hazlo una vez por semana o cada quince días. Siempre aplica un tónico tras una exfoliación o limpieza para reequilibrar el pH de la piel."), "\n", React.createElement(BannerRecap, {
    text: "Para evitar irritación, siempre exfolia sobre piel húmeda y de noche"
  }), "\n", React.createElement(_components.h1, null, "5. Suplementación Endovenosa"), "\n", React.createElement(_components.p, null, "¿Has oído hablar de la suplementación endovenosa? Este es un método de tratamiento novedoso que puede equilibrar el cuerpo para prevenir enfermedades, activar el sistema inmunológico, proporcionar energía y vitalidad al cuerpo, y desintoxicar."), "\n", React.createElement(_components.p, null, "Dado que no todos los nutrientes se pueden ingerir por vía oral, la vía endovenosa garantiza una absorción adecuada de las combinaciones personalizadas a base de vitaminas, minerales y aminoácidos. Alguno de los beneficios son: Previene los signos del envejecimiento, aumenta la energía, el rendimiento físico y mental. Rebaja los niveles de estrés y aumenta la concentración. Actúa como reparador celular, combate los radicales libres y elimina toxinas."), "\n", React.createElement(EmbededImageLocal, {
    alt: "Vitaminas y minerales en la suplementación endovenosa",
    image: props.data.mdx.frontmatter.embeddedImagesLocal[3]
  }), "\n", React.createElement(BannerRecap, {
    text: "Es uno de los tratamientos estrella que se realizan en Clinica Planas."
  }), "\n", React.createElement(_components.h1, null, "6. Dejar de fumar"), "\n", React.createElement(_components.p, null, "Deja de fumar. Las arrugas provocadas por los cigarrillos son peores que las provocadas por el sol."), "\n", React.createElement(_components.p, null, "Fumar priva a la piel de colágeno y elastina, que le dan textura, fuerza y elasticidad a la piel, acelerando así el proceso de envejecimiento."), "\n", React.createElement(EmbededImageLocal, {
    alt: "Rompe tu adicción a fumar",
    image: props.data.mdx.frontmatter.embeddedImagesLocal[4]
  }), "\n", React.createElement(_components.h1, null, "7. Agua"), "\n", React.createElement(_components.p, null, "Bebe 2 litros de agua al día para ayudar a los riñones a filtrar mejor, eliminar toxinas y mejor rendimiento intelectual. Pero dependiendo de la piel y de la edad, eso no alcanza para tener un rostro hidratado y luminoso, por eso es importante la rehidratación cutánea a base de infiltraciones de ácido hialurónico o vitaminas."), "\n", React.createElement(_components.p, null, "Como una esponja molecular, el ácido hialurónico atrae grandes cantidades de agua a su alrededor, estimulando la formación de colágeno y elastina, y tiene una fuerte capacidad antioxidante para prevenir los radicales libres. También se puede infiltrar dentro de la piel un compuesto de varios productos con gran capacidad de hidratación como vitaminas, aminoácidos, minerales, antioxidantes. Este es un tratamiento seguro y su efecto se puede apreciar desde la primera sesión."), "\n", React.createElement("a", {
    href: "https://www.clinicaplanas.com/medicina-estetica-facial/mesoplastia-facial"
  }, React.createElement(BannerRecap, {
    text: "Puedes obtener más información sobre el tratamiento pinchando aquí"
  })), "\n", React.createElement(_components.h1, null, "8. ¡Muévete!"), "\n", React.createElement(_components.p, null, "¡Muévete! El ejercicio cardiovascular reduce el estrés y te hace más feliz. Haz cualquier tipo de ejercicio aeróbico durante media hora todos los días para que tu frecuencia cardíaca supere la línea base. Sal a caminar, anda en bicicleta por la playa o usa la elíptica si es que la tienes abandonada en casa. Haz algo que te haga sudar un poco."), "\n", React.createElement(_components.p, null, "Estudios recientes han demostrado que los pacientes que practican deporte regularmente, muestran menos síntomas depresivos, promoviendo así un estado emocional positivo, estable y optimista. Además permite conciliar el sueño con más facilidad."), "\n", React.createElement(BannerRecap, {
    text: "¡Mens sana in corpore sano!"
  }), "\n", React.createElement(_components.h1, null, "9. ¡Estira!"), "\n", React.createElement(EmbededImageLocalSide, {
    alt: "Maquina para estirar",
    image: props.data.mdx.frontmatter.embeddedImagesLocal[5]
  }, React.createElement(_components.p, null, "¡Estira! Los músculos tensos pueden provocar una mala postura, por eso el estiramiento\nes tu gran aliado. Estirar puede mejorar la postura, y una buena postura no solo\npreviene de dolores de espalda, cuello y posibles lesiones, sino que mejora la respiración,\nestiliza visualmente el cuerpo contribuyendo a una pose elegante, ayudando a verte\ny sentirte espléndida!"), React.createElement(_components.p, null, "Recomiendo usar la HUBER 360 EVOLUTION, una máquina novedosa creada para la realización de terapias de rehabilitación y entrenamiento neuromuscular que te permite mover de 80 a 100 grupos musculares por ejercicio."), React.createElement(_components.p, null, "También puedes probar y/o complementar con yoga o pilates, ya que estos tienen ejercicios que también proporcionan mayor flexibilidad. Mejoran además la fuerza y el tono de los músculos, especialmente los músculos abdominales, la zona lumbar, cadera y los glúteos; los llamados \"músculos centrales\" de tu cuerpo.")), "\n", React.createElement(_components.h1, null, "10. Almohada Antiaging"), "\n", React.createElement(EmbededImageLocalSide, {
    alt: "Almohada antiaging",
    image: props.data.mdx.frontmatter.embeddedImagesLocal[6]
  }, React.createElement(_components.p, null, "Invierte en fundas de almohada Antiaging. Las fundas de almohada de seda son consideradas antiaging, ya que pueden prevenir arrugas. Esto da como resultado menos marcas de arrugas en comparación con las fundas de almohada tradicionales, que tienden a acumularse.Su gran beneficio además es que la seda es hipoalergénica y no absorbe humedad de la piel, manteniéndola hidratada."), React.createElement(_components.p, null, "Pero eso no es todo; gracias a la textura súper suave de la seda, el cabello se desliza sin ningún tipo de fricción, lo que significa que es menos probable que te despiertes con el cabello encrespado. En cambio el algodón es un material que absorbe la humedad, y eso es lo que hace con tu cabello.")), "\n", React.createElement(BannerRecap, {
    text: "Dile adiós a esas molestas marcas de la almohada en la cara."
  }), "\n", React.createElement(_components.h1, null, "11. Pantalla del Teléfono"), "\n", React.createElement(_components.p, null, "Limpiar la pantalla del teléfono: Limpia regularmente la pantalla de tu smartphone de restos de maquillaje y bacterias para evitar las imperfecciones."), "\n", React.createElement(_components.p, null, "Es fácil propagar bacterias cutáneas dañinas desde la superficie del teléfono."), "\n", React.createElement(EmbededImageLocal, {
    alt: "Tocando la pantalla de un mobil",
    image: props.data.mdx.frontmatter.embeddedImagesLocal[7]
  }), "\n", React.createElement(_components.h1, null, "12. Cuidado con el agua caliente"), "\n", React.createElement(_components.p, null, "Las duchas de agua caliente aunque pueden ser muy relajantes pueden deshidratar tu piel."), "\n", React.createElement(_components.p, null, "La exposición regular al agua caliente, elimina la capa protectora de lípidos que mantienen la humedad y crean barrera contra las bacterias nocivas. Además pueden crean erupciones y agravar cuadros de eczema o piel seca."), "\n", React.createElement(EmbededImageLocal, {
    alt: "Mujer en un baño",
    image: props.data.mdx.frontmatter.embeddedImagesLocal[8]
  }), "\n", React.createElement(_components.h1, null, "13. Escote Hidratado"), "\n", React.createElement(_components.p, null, "Cuando te hidrates la cara, no te olvides del cuello y escote."), "\n", React.createElement(_components.p, null, "La piel del cuello y del escote con el paso del tiempo va perdiendo colágeno, y si a esto le sumamos la exposición al sol sin factor de protección solar, uso de sujetador incorrecto y la insuficiente ingesta de agua, se puede reflejar una edad mucho más avanzada de la que en realidad se tiene."), "\n", React.createElement(EmbededImageLocal, {
    alt: "Hidratar el cuello y el escote",
    image: props.data.mdx.frontmatter.embeddedImagesLocal[9]
  }), "\n", React.createElement(BannerRecap, {
    text: "Otro gran desconocido que puede provocar arrugas en el pecho es dormir de lado."
  }), "\n", React.createElement(_components.h1, null, "14. Migrañas"), "\n", React.createElement(_components.p, null, "Si sufres de migraña o jaqueca ¡prueba con dormir boca arriba!"), "\n", React.createElement(_components.p, null, "Al principio puede resultarte incómodo, pero si te pones un cojín bajo las rodillas y en la zona lumbar, puede llegar a ser muy placentero y en muchas ocasiones, disminuye la frecuencia de los dolores de cabeza."), "\n", React.createElement(EmbededImageLocal, {
    alt: "Dolor de cabeza",
    image: props.data.mdx.frontmatter.embeddedImagesLocal[10]
  }), "\n", React.createElement(_components.h1, null, "15. Micro Botox"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "El secreto mejor guardado")), "\n", React.createElement(_components.p, null, "El micro-botox es al botox como el no-makeup al makeup. La toxína botulínica siempre fue muy recomendada para la prevención de arrugas faciales, ya que bloquea el músculo,"), "\n", React.createElement(_components.p, null, "relajando su contracción. De esta manera no se forman arrugas. Su efecto es temporal y dura unos meses."), "\n", React.createElement(_components.p, null, "La técnica de micro-botox provoca que las glándulas sebáceas se atrofien, por lo que se reduce visiblemente el tamaño del poro y la piel se ve más lisa, con más luz.Yo lo recomiendo a los pacientes que tienen piel grasa, con mucho brillo por que el micro-botox tiene un efecto visible sobre la calidad de la piel, ya que regula la glándula sebácea y la glándula sudorípara. Estás se atrofian, reduciendo el tamaño del poro, pudiendo apreciarse una piel mucho más tersa, uniforme y joven, retrasando el envejecimiento y la aparición de nuevas arrugas. Las inyecciones se hacen superficialmente en la piel, sin afectar a los músculos faciales pudiendo realizarse en mejillas, mentón, cuello y escote."), "\n", React.createElement(BannerRecap, {
    text: "El resultado es una cara más relajada y descansada, con aspecto rejuvenecido ¡au naturel!"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
